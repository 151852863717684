@import "styles/constants.css";

header {
  position: fixed;
  width: 100%;
  top: 0;
  background: transparent;
  z-index: 2;
}

.navbar-background {
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  background: var(--bg-dark-primary);
}

.alt-header {
  position: fixed;
  /* max-width: 1700px;
  margin: 0 auto; */
}

.nav-wrap {
  max-width: 1700px;
  margin: 0 auto;
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  /* max-width: 1700px; */
  /* margin: 0 auto; */
  justify-content: space-between;
  align-items: center;
  padding: 0px 4%;
}

.alt-navbar-background {
  background: var(--alt-bg-dark-primary);
}

.navbar-transparent {
  height: var(--navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 64px;
  background: transparent;
}

.alt-navbar-transparent {
  background: transparent;
}

.navbar-menu,
.alt-navbar-menu {
  display: flex;
  justify-content: space-between;
  right: 0;
  align-items: center;
  gap: 35px;
}

li {
  list-style: none;
}

.navbar-link {
  color: #fff;
  text-decoration: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
}

.navbar-alt-link,
.navbar-alt-link-active {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  color: var(--text-light-primary);
  padding-bottom: 5px;
  transition: all 0.3s ease;
}

.navbar-alt-link-active {
  border-bottom: 1px solid rgba(199, 196, 231, 0.55);
}

.social-media-img {
  height: 25px;
  margin-bottom: -6px;
}

.navbar-link:hover {
  cursor: pointer;
}

.link-stake {
  color: var(--pink-primary);
  padding: 12px 35px;
}

.item-stake {
  border-radius: 10px;
  padding: 10px 0px;
}

.navbar-punks-logo {
  max-width: 100%;
  height: 58px;
}

.navbar-punks-logo:hover,
.shopping-bag:hover {
  cursor: pointer;
}

.navbar-pv-logo {
  max-width: 100%;
  height: 38px;
}

.navbar-alt-link:hover,
.navbar-alt-link-active:hover {
  color: #8c5bff;
}

.acc-bal:hover {
  color: var(--text-light-primary);
}

.navbar-link:hover {
  color: #d93978;
}

.vert-line {
  border-right: 1px solid rgba(199, 196, 231, 0.3);
}

.left-of-nav {
  display: flex;
  flex-direction: row;
  gap: 20px;
  z-index: 1000;
}

.left-nav-header {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.navbar-icons {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.shopping-bag {
  height: 40px;
  max-width: 100%;
}

.profile-icon {
  height: 34px;
  max-width: 100%;
}

.navbar-icons .btn {
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 72px !important;
  color: var(--text-light-primary);
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  height: 40px;
  transition: all 0.3s ease;
}

.navbar-icons .btn:hover {
  border: 1px solid rgba(231, 231, 238, 0.7);
}

.item-stake:hover {
  background: #d93978;
}

.link-stake:hover {
  color: #fff;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.alt-hamburger {
  display: none;
  cursor: pointer;
  z-index: 1000;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: rgb(24, 254, 23);
}

.alt-bar {
  display: block;
  width: 25px;
  height: 3px;
  margin: 5px auto;
  background-color: var(--text-light-primary);
}

.bar:nth-child(2),
.alt-bar:nth-child(2) {
  width: 115%;
}

.cart-cont {
  position: relative;
}

.cart-amount {
  left: 25px;
  top: -5px;
  background: #8c5bff;
  border-radius: 71px;
  width: 22px;
  height: 22px;
  position: absolute;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
}

.cart-amount-yellow {
  left: 25px;
  top: -5px;
  background: #FFB800;
  border-radius: 71px;
  width: 22px;
  height: 22px;
  position: absolute;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 1em;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
}

@keyframes bounce {
  10%,
  90% {
    transform: translate3d(0, -1px, 0);
  }

  20%,
  80% {
    transform: translate3d(0, 2px, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(0, -4px, 0);
  }

  40%,
  60% {
    transform: translate3d(0, 4px, 0);
  }
}

.nudge-btn {
  animation: bounce 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}

@media (max-width: 875px) {
  .alt-hamburger,
  .hamburger {
    display: block;
  }

  .hamburger.active .bar:nth-child(2),
  .alt-hamburger.active .alt-bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active .bar:nth-child(1),
  .alt-hamburger.active .alt-bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger.active .bar:nth-child(3),
  .alt-hamburger.active .alt-bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }

  .profile-icon {
    position: relative;
  }

  .navbar > a {
    z-index: 1;
  }

  .navbar-menu {
    position: fixed;
    top: 0px;
    left: -100%;
    flex-direction: column;
    background-color: #0c1121;
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .alt-navbar-menu {
    position: fixed;
    top: 0px;
    left: -100%;
    flex-direction: column;
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .navbar-link {
    font-size: 28px;
  }

  .navbar-link > img {
    height: 35px;
  }

  .link-stake {
    color: #fff;
  }

  .item-stake {
    border: none;
  }

  .item-stake:hover {
    background: none;
  }

  .link-stake:hover {
    color: #d93978;
  }

  .navbar-menu.active,
  .alt-navbar-menu.active {
    left: 0;
  }
}

@media (max-width: 715px) {
  .navbar-icons .left-nav-header {
    display: none;
  }
}
@media (max-width: 600px) {
  .navbar-pv-logo {
    height: 28px;
  }
  .left-of-nav {
    flex-direction: column;
    gap: 0;
  }

  .navbar-icons {
    gap: 10px;
  }

  .shopping-bag {
    height: 30px;
  }

  .profile-icon {
    height: 28px;
  }

  .navbar-icons .btn {
    height: 30px;
    font-size: 10px;
    line-height: 175%;
  }
  .left-nav-header {
    font-size: 10px;
  }
}

@media (max-width: 425px) {
  .navbar-link {
    font-size: 18px;
  }
}
