.checkout-page-layout {
  display: flex;
  flex-direction: row;
  max-width: 1700px;
  margin: 5% auto 10% auto;
  padding: 0 4%;
  justify-content: space-between;
}

.checkout-page-header {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 49px;
  line-height: 100%;
  color: var(--text-light-primary);
  margin-bottom: 1em;
}

.order-summary {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23vw;
  align-self: flex-start;
}

.top-order-heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
}

.top-order-heading h2 {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  text-align: center;
  color: var(--text-light-primary);
  letter-spacing: 0;
  text-transform: none;
}

.top-order-heading span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
}

.top-order-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 10px;
  overflow-x: hidden;
  overflow: auto;
}

.bottom-order-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 16px;
  height: 20vh;
  gap: 5px;
}

.bottom-order-cont span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: var(--text-light-primary);
}

.order-total,
.shipping-total {
  background: rgba(199, 196, 231, 0.08);
  backdrop-filter: blur(50px);
  border-radius: 11.0665px;
}

.order-total {
  display: flex;
  flex-direction: column;
  padding: 11px;
}

.order-total span:last-child {
  text-transform: uppercase;
}

.order-total span,
.shipping-total span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 175%;
  color: var(--text-light-primary);
}

.order-total img {
  height: 12px;
}

.or-text {
  color: rgba(199, 196, 231, 0.55) !important;
}

.shipping-total {
  display: flex;
  justify-content: space-between;
  padding: 11px;
}

.order-item-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: rgba(199, 196, 231, 0.08);
  backdrop-filter: blur(50px);
  border-radius: 11px;
  padding: 10px;
}

.x-remove {
  color: var(--text-light-primary);
  cursor: pointer;
  width: 40px;
  height: 40px;
  line-height: 0;
  font-size: 0;
  position: absolute;
  right: -5px;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  transition: all 0.3s ease;
}

.x-remove:before,
.x-remove:after {
  content: "";
  height: 1px;
  background: #fff;
  width: 1em;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  font-size: 15px;
  position: absolute;
}

.x-remove:before {
  transform: rotate(45deg);
}

.x-remove:after {
  transform: rotate(-45deg);
}

.cart-item-cont:hover .x-remove,
.cart-item-cont:focus .x-remove,
.order-item-cont:hover .x-remove,
.order-item-cont:focus .x-remove {
  right: 10px;
  opacity: 1;
}

.order-item-cont > :first-child {
  height: 61px;
}

.country-step-info-text {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.checkout-steps-flow {
  width: 100%;
}

.checkout-step {
  background: rgba(199, 196, 231, 0.08);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(50px);
  border-radius: 15px;
  margin-bottom: 10px;
  margin-right: 25px;
  padding: 1em 2em;
}

.checkout-step-title {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  gap: 15px;
  align-items: center;
  padding: 15px;
}

.checkout-step-title.opened {
  justify-content: center;
  margin-top: 4em;
}

.checkout-step-title h2 {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  text-align: center;
  color: var(--text-light-primary);
  text-transform: none;
  letter-spacing: 0;
  margin: 0;
}

.checkout-step-title span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
}

.checkout-step-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  max-height: 0;
  transition: ease-in 0.2s cubic-bezier(0, 1, 0, 1);
  /* margin-left: 20px; */
}

.ship-est {
  align-items: flex-start !important;
}

.checkout-step-info.opened {
  padding: 2em 0 0 0;
  height: auto;
  max-height: 100%;
  transition: ease-out 0.3s cubic-bezier(1, 0, 1, 0);
  margin-bottom: 4em;
}

.checkout-step-info-text {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
  margin-bottom: 3em;
}

.transaction-error-message {
  border-left: 2px solid #dd3434;
  padding-left: 5px;
  margin-bottom: 5px;
}

.checkout-step-info .btn {
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 72px !important;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.payment-options-buttons {
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
}

.ship-est-btns {
  display: flex;
  flex-direction: column !important;
  gap: 12px;
  align-items: flex-start !important;
}

.payment-options-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.payment-options-button div {
  font-size: 10px;
  margin-top: 1em;
}

.payment-option-btn {
  padding: 10px 22px 8px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 72px;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.payment-option-btn-disabled {
  background: rgba(29, 29, 29, 0.01) !important;
  border: 1px solid rgba(66, 66, 66, 0.2) !important;
  color: rgba(133, 133, 133, 0.2) !important;
  padding: 10px 22px 8px;
  border-radius: 72px;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
}

.payment-option-btn-disabled button:hover {
  border: none !important;
}

/* .payment-option-btn-disabled {
  padding: 10px 22px 8px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 72px;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-primary);
  filter: brightness(0.75);
} */

.payment-option-btn:hover {
  filter: brightness(1.5);
  cursor: pointer;
}

.merch-user-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.merch-checkout-disclaimer {
  color: #ffb800;
  text-align: center;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: 1em;
  padding: 0% 2%;
}

#info-link {
  color: #ffb800;
  border-bottom: 1px solid #ffb800;
}

#info-link:hover {
  cursor: pointer;
}

.order-to-shop {
  color: var(--text-light-primary);
}

.order-to-shop:hover {
  color: var(--text-light-primary);
}

.opened-shipping-estimate {
  width: 100%;
}

#country-select {
  width: 98%;
  height: auto;
  padding: 8px;
  background: #222222;
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 8px;
  font-weight: 700;
  font-size: 19px;
  line-height: 140%;
  color: var(--text-light-primary);
  margin: 0px 1px;
  /* text-align: center; */
  text-transform: uppercase;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("images/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  padding-right: 1em;
}

select option {
  color: var(--text-light-primary);
}

select option[value=""],
select:invalid {
  color: rgba(199, 196, 231, 0.55) !important;
}

/* SHIPPING FORM CSS */
/* 
.shipping-section {
  display: flex;
  flex-direction: column;
}

.shipping-section h2 {
  font-size: 23px;
}

.shipping-section-complete {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.shipping-section-complete h2 {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  text-align: center;
  color: var(--text-light-primary);
}

.form-container {
  display: flex;
  justify-content: space-between;
  margin: 2% 0;
  width: 100%;
}

.input-two {
  width: 48%;
}

.input-one {
  width: 100%;
}

.field-label {
  font-family: "PP Telegraf";
  font-weight: 500;
  font-size: 10px;
}

.field-label-two {
  font: "PP Telegraf";
  font-weight: 500;
  font-size: 13px;
  line-height: 22.75px;
}

.shipping-input {
  color: white;
  width: 100%;
  background: rgba(199, 196, 231, 0.08);
  border: 1px solid rgba(199, 196, 231, 0.15);
  border-radius: 8px;
  padding: 15px;
}

.continue-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 22px 8px;
  gap: 10px;
  width: 133px;
  height: 44px;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 72px;
}

.your-shipping-info {
  display: flex;
  flex-direction: column;
}

.shipping-info-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2%;
}

.right {
  text-align: right;
} */

.checkout-edit-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 12px;
  font-size: 13px;
  color: white;
  background: rgba(199, 196, 231, 0.08);
  border: none;
  border-radius: 7px;
}

.text-edit-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
  flex-grow: 1;
}

.text-edit-cont div:nth-child(2) {
  display: flex;
  align-items: center;
  gap: 20px;
}

@media screen and (max-width: 1700px) {
  .order-summary {
    width: 40vw;
  }
}

@media screen and (max-width: 950px) {
  .checkout-page-layout {
    flex-direction: column-reverse;
    align-items: center;
  }
  .order-summary {
    width: 100%;
  }
  .top-order-heading {
    align-items: center;
  }
  .top-order-items {
    padding: 0;
  }
  .checkout-step {
    margin-right: 0;
  }
  .bottom-order-cont {
    padding: 0px 0px 20px 0px;
  }
  .checkout-step h2 {
    font-size: 3vw;
  }
  .checkout-step span {
    font-size: 2vw;
  }
  /* .checkout-step-title span {
    margin-left: 6rem;
  } */
  .text-edit-cont {
    gap: 10px;
  }
  .text-edit-cont div:nth-child(2) {
    gap: 10px;
  }
}

@media screen and (max-width: 675px) {
  .checkout-edit-btn {
    font-size: 10px;
  }
}

@media screen and (max-width: 450px) {
  /* .checkout-step-title span {
    margin-left: 4rem;
  } */
  .payment-option-btn-disabled {
    font-size: 12px;
  }
  .text-edit-cont {
    flex-direction: column;
  }
  .text-edit-cont div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-edit-cont div:nth-child(2) {
    flex-direction: column;
  }
  .checkout-page-header {
    font-size: 32px;
  }
  .top-order-heading h2 {
    font-size: 32px;
  }
  .checkout-step h2 {
    font-size: 4vw;
  }
  .checkout-step span {
    font-size: 3vw;
  }
  .country-step-info-text {
    font-size: 11px;
  }
  #country-select {
    font-size: 12px;
  }
  .payment-option-btn {
    font-size: 11px;
    padding: 10px 16px 8px;
  }
  .payment-options-buttons {
    display: flex;
    flex-direction: column;
  }

  .text-edit-cont {
    gap: 10px;
  }
  .text-edit-cont div:nth-child(2) {
    gap: 5px;
  }
}
