.description-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap-reverse;
    margin: auto;
    justify-content: center;
    padding: 5rem 4%;
    max-width: 1700px;
}

.description-container-background {
    background-image: url('../../images/ApeBackground.jpg');
}

.reverse {
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;    
}

.description-column {
    width: 60%;
}

.image-column .normal {
    width: 40%;
}

.image-column > img {
    width: 36rem;
}

.image-column.large {
    width: 40%;
}

.image-column.large > img {
    width: 100%;
    object-fit: contain !important;
    overflow: hidden !important;
}

.image-column > img {
    object-fit: contain;
    overflow: hidden;
}

@media (max-width: 1250px) {
    .image-column.large > img {
        max-width: 524px;
    }

    .description-container {
        gap: 3rem;
        padding: 5rem 4rem;
    }
}
@media screen and (max-width: 768px) {
    .image-column.large  > img {
        max-width: 473px !important;
        height: 382px;
        margin-left: 5px;
    }

    .image-column > img {
        width: 30rem;
    }

    .description-container {
        gap: 3rem;
        padding: 5rem 4rem;
    }
}

@media screen and (max-width: 429px) {
    .image-column.large > img,
    .image-column > img {
        width: 92vw !important;
    }

    .image-column.large > img {
        margin-top: -11px;
        margin-bottom: -46px;
    }

    .image-column > img {
        margin-top: 1rem;
    }

    .description-container {
        padding: 3rem 1.5rem !important;
    }
}