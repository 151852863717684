.merch-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(199, 196, 231, 0.08);
  backdrop-filter: blur(20px);
  border-radius: 13px;
  padding: 18px;
  gap: 18px;
  font-family: "PP Telegraf";
  font-style: normal;
  /* z-index: 1; */
}

.loader-ring-cont {
  position: relative;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: pulse 1.5s ease-out infinite;
}

.loader-ring:nth-child(2) {
  animation-delay: -0.8s;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.2;
  }
}

.prev-arrow {
  z-index: 1;
  height: 30px !important;
  position: absolute;
  left: 1em;
}

.next-arrow {
  height: 30px !important;
  position: absolute;
  right: 1em;
}

.curr-item-img:hover,
.next-arrow:hover,
.prev-arrow:hover {
  transform: scale(1) !important;
}

.merch-info-row {
  display: flex;
  flex-direction: row;
  gap: 28px;
  padding: 1em 0;
  border-top: 1px solid rgba(199, 196, 231, 0.08);
  border-bottom: 1px solid rgba(199, 196, 231, 0.08);
}

.merch-info-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.merch-title {
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  text-align: center;
  color: var(--text-light-primary);
}

.merch-smtitle {
  font-weight: 500;
  font-size: 10px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.merch-info-bold {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
}

.merch-info-bold img {
  height: 16px;
}

.merch-info-light {
  font-weight: 500;
  font-size: 10px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: rgba(199, 196, 231, 0.8);
}

.merch-selection-btns {
  display: flex;
  flex-direction: row;
  gap: 12px;
  width: 100%;
}

#merch-sizes {
  width: 160px;
  height: 40px;
  background: #222222;
  border: none;
  backdrop-filter: blur(29px);
  border-radius: 8px;
  font-weight: 700;
  font-size: 19px;
  line-height: 140%;
  color: var(--text-light-primary);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("images/arrow.svg");
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  padding-right: 1em;
}

#merch-sizes:focus,
#merch-sizes:hover {
  outline: none;
  border: 1px solid rgba(231, 231, 238, 0.7);
}

.claimed-select-disabled {
  background: rgba(29, 29, 29, 0.01) !important;
  border: 1px solid rgba(66, 66, 66, 0.2) !important;
  color: rgba(133, 133, 133, 0.2) !important;
}

.claimed-select-disabled:focus,
.claimed-select-disabled:hover {
  border: none !important;
}

.merch-selection-btns button {
  width: 100%;
  height: 40px;
  border-radius: 72px;
  color: var(--text-light-primary);
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  transition: all 0.3s ease;
}

.merch-selection-btns button:hover {
  border: 1px solid rgba(231, 231, 238, 0.7);
}

.claimed-disabled {
  background: rgba(29, 29, 29, 0.01) !important;
  border: 1px solid rgba(66, 66, 66, 0.2) !important;
  color: rgba(133, 133, 133, 0.2) !important;
}

.claimed-disabled button:hover {
  border: none !important;
}
