@import './constants.css';

h1, h2, h3, h4, h5, h6 {
    font-family: 'PP Telegraf';
    color: var(--pink-primary);
    letter-spacing: 0.15em;
    text-transform: uppercase;
}

a {
    cursor: pointer;
}

button {
    font-size: 16px;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-primary {
    background-color: var(--pink-primary);
    color: var(--text-dark-primary);
    font-weight: 800;
}

.btn-secondary {
    background-color: transparent;
    color: var(--pink-primary) !important;
    border: 0.73px solid var(--pink-primary) !important;
}

.h3rem {
    height: 3rem !important;
}

.btn-primary-green {
    background-color: var(--green-primary);
    color: var(--text-dark-primary);
    font-weight: 800;
}

.btn-primary-green:hover {
    color: var(--text-dark-primary);
}

.btn-primary-transparent {
    background-color: transparent;
    color: white !important;
    font-weight: 800;
    border: 0.73px solid white !important;
}

.text-primary {
    color: var(--text-light-primary);
    margin: 2rem 0rem;
    line-height: 31.5px;
    font-weight: 500;
}

.text-primary-green {
    color: var(--green-primary);
}

.text-light {
    color: var(--text-light-primary);
}

.header-primary {
    line-height: 42.42px;
    font-size: 28px;
    font-family: 'Issue';
    font-style: italic;
}

/** actions */
.success {
    color: var(--green-primary);
}

.warning {
    color: var(--pink-primary) !important;
}

.default {
   color: var(--text-light-primary);
}