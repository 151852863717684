.merch-accordion {
  max-width: 789px;
}

.accord-item-cont {
  background: rgba(199, 196, 231, 0.08);
  backdrop-filter: blur(29px);
  border-radius: 13px;
  margin-bottom: 10px;
  padding: 20px;
  cursor: pointer;
}

.accord-item-question {
  display: flex;
  gap: 18px;
  align-items: center;
}

.accord-item-question h2,
.accord-item-question span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
  color: var(--text-light-primary);
  margin-top: 0;
  text-transform: none;
  letter-spacing: 0;
}

.accord-item-question span {
  width: 20px;
  text-align: center;
  font-size: 30px;
  line-height: 1;
  transform: translateY(-2px)
}

.accord-item-answer {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  color: var(--text-light-primary);
  overflow: hidden;
  /* max-height: 0; */
  transition: ease-in 0.2s cubic-bezier(0, 1, 0, 1);
  margin-left: 38px;
  margin-top: 7px;
}

.accord-item-answer.opened {
  height: auto;
  max-height: 100%;
  transition: ease-out 0.3s cubic-bezier(1, 0, 1, 0);
}
