.merch-page {
  width: 100%;
  color: var(--text-light-primary);
  /* padding-top: 0; */
  position: relative;
  /* z-index: 1; */
  padding-top: var(--navbar-height);
}

.shadow {
  z-index: -10;
  position: absolute;
  background: url("../../images/shadow.png");
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 95vh;
}

.image-modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 10;
  background: rgba(19, 19, 19, 0.9);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  align-items: center;
  justify-content: space-around;
  overflow-y: scroll;
  padding: 7%;
}

.image-modal-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  /* max-height: 250px; */
}

.image-modal-image {
  max-width: 90%;
  max-height: 90%;
  /*   
  width: 57%;
  margin: auto;
  object-fit: contain; */
}

.opened-img-details {
  background: rgba(199, 196, 231, 0.08);
  border-radius: 24px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(20px);
  width: 50%;
  /* overflow-y: scroll; */
}

.opened-img-title,
.opened-img-desc,
.opened-img-subtitle,
.info-grid-title,
.info-grid-detail,
.info-grid-detail2 {
  font-family: "PP Telegraf";
  font-style: normal;
}

.opened-img-title {
  font-weight: 700;
  font-size: 32px;
  line-height: 125%;
  color: var(--text-light-primary);
  margin-bottom: 12px;
}

.opened-img-desc {
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
  color: var(--text-light-primary);
  margin-bottom: 20px;
}

.opened-img-subtitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  margin: 16px 0px;
  color: var(--text-light-primary);
}

.opened-img-info-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.info-grid-dets {
  display: flex;
  flex-direction: column;
  background: rgba(199, 196, 231, 0.08);
  border-radius: 12px;
  padding: 15px;
}

.info-grid-title {
  font-weight: 500;
  font-size: 10px;
  line-height: 175%;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: rgba(199, 196, 231, 0.8);
}

.info-grid-detail {
  font-weight: 600;
  font-size: 15px;
  line-height: 175%;
  color: var(--text-light-primary);
}

.info-grid-detail2 {
  font-weight: 600;
  font-size: 13px;
  line-height: 17px;
  color: rgba(199, 196, 231, 0.8);
}

.close-image-modal {
  display: flex;
  flex-direction: flex-end;
  /* gap: 10px; */
  /* position: absolute;
  right: 128px;
  top: 50px; */
  width: 100%;
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-primary);
  justify-content: flex-end;
  align-items: center;
}

.x-close-image {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1% 6%;
  gap: 10px;

  cursor: pointer;
}

.x-close-image div {
  margin-bottom: 4%;
}

.x-close-image div:before,
.x-close-image div:after {
  content: "";
  position: absolute;
  width: 28px;
  height: 1px;
  background: #fff;
  /* top: 0;
  right: 0;
  left: 64px;
  bottom: 0; */
}

.x-close-image div:before {
  transform: rotate(45deg);
}

.x-close-image div:after {
  transform: rotate(-45deg);
}

.merch-banner {
  width: 100%;
  height: 95vh;
  position: absolute;
  z-index: 0;
  object-fit: cover;
  background: radial-gradient(98.62% 63.42% at 50% 50%, rgba(0, 0, 0, 0) 3.12%, rgba(0, 0, 0, 0.69) 100%),
    linear-gradient(360deg, rgba(0, 0, 0, 0) 78.42%, rgba(0, 0, 0, 0.7) 100%),
    linear-gradient(180deg, rgba(0, 0, 0, 0) 3.12%, #000000 100%), url("../../images/shopBanner.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.merch-btn {
  background: rgba(255, 255, 255, 0.01);
  border: 1px solid rgba(199, 196, 231, 0.3);
  backdrop-filter: blur(29px);
  border-radius: 72px !important;
  color: var(--text-light-primary);
  font-size: 1.5em;
  transition: all 0.3s ease;
}

.merch-btn:hover {
  border: 1px solid rgba(231, 231, 238, 0.7);
}

.merch-banner h2 {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 100%;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.merch-banner p {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 175%;
  color: var(--text-light-primary);
}

.merch-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  padding: 0 4%;
  max-width: 1700px;
}

.shop-closed-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50vh;
}

.shop-closed-smtitle {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.shop-closed-title {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 62px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.shop-closed-text {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
}

.main-skills-reel {
  width: 100%;
  overflow: hidden;
}

.skills-reel {
  display: flex;
  align-items: center;
  white-space: nowrap;
  width: fit-content;
  will-change: transform;
  animation: animation-reel 30s infinite linear;
}

@keyframes animation-reel {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-80%);
  }
}

@media screen and (max-width: 1100px) {
  .merch-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 758px) {
  .image-modal {
    display: inline-block;
    padding: 10% 5%;
  }
  .image-modal-container {
    flex-direction: column;
  }
  .close-image-modal {
    position: static;
  }
  .merch-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .image-container {
    width: auto;
    max-height: 250px;
  }
  .image-modal-image {
    max-height: 250px;
  }
  .opened-img-details {
    width: auto;
  }
}

@media screen and (min-width: 500px) {
  .merch-page .description-container .description-column h2 {
    width: 85%;
  }
}
