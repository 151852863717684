.merch-info-container {
  display: flex;
  flex-direction: row;
  max-width: 1700px;
  margin: 5% auto 10% auto;
  padding: 0 8%;
}

.merch-sidebar {
  flex: 17% 1;
  max-width: 240px;
  margin-right: 4%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
  align-self: flex-start;
}

.merch-info-content {
  flex: 83% 1;
  display: flex;
  /* z-index: 1; */
  flex-direction: column;
  gap: 40px;
}

.merch-sidebar a {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 22px 8px;
  grid-gap: 10px;
  gap: 10px;
  width: 241px;
  height: 44px;
  background: rgba(199, 196, 231, 0.08);
  border: 1px solid rgba(199, 196, 231, 0.3);
  -webkit-backdrop-filter: blur(14.5px);
  backdrop-filter: blur(14.5px);
  border-radius: 72px;
  flex: none;
  order: 1;
  flex-grow: 0;
  color: #fff;
  transition: all 0.3s ease;
}

.merch-sidebar a:hover,
.merch-sidebar a:focus {
  border-color: #fff;
}

.info-merch-title {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 49px;
  line-height: 100%;
  color: var(--text-light-primary);
  margin-bottom: 19px;
}

.info-merch-text {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  color: var(--text-light-primary);
}

.size-charts img {
  height: 35vw;
}

@media screen and (max-width: 700px) {
  .merch-sidebar {
    display: none;
  }

  .size-charts img {
    height: 60vw;
  }
}
