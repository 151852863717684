.btn {
    font-family: "PP Telegraf";
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 175%;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-feature-settings: "pnum" on, "lnum" on;
    justify-content: center;
    align-items: center;
    border: none;
    text-decoration: none;
    padding: .5em 1.1em .4em;
}

.btn a {
    text-decoration: none;
    color: #0c1121;
}

.btn a:visited {
    text-decoration: none;
    color: #0c1121;
}
