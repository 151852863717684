.cart-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 100px;
  right: -100%;
  width: 25vw;
  height: 80vh;
  background: rgba(9, 9, 9, 0.83);
  border: 1px solid rgba(231, 246, 255, 0.2);
  backdrop-filter: blur(29px);
  border-radius: 13px;
  z-index: 1500;
  transition: ease-in 0.3s;
  overflow: hidden;
}

.opened-cart-modal {
  right: 30px;
  transition: ease-out 0.3s;
}

.top-cart-cont {
  flex: 0;
  align-self: stretch;
  flex-grow: 1;
  order: 0;
  overflow-y: scroll;
}

.top-cart-heading {
  display: flex;
  flex-direction: column;
  padding: 2.5em 16px;
}

.top-cart-heading h2 {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 41px;
  line-height: 125%;
  text-align: center;
  color: var(--text-light-primary);
  text-transform: none;
  letter-spacing: 0 !important;
}

.top-cart-heading span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
}

.bottom-cart-cont {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 16px;
  background: rgba(112, 112, 124, 0.2);
  height: auto;
}

.bottom-cart-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 1.5em;
}

.bottom-cart-info img {
  height: 16px;
  transform: translateY(0.15em);
}

.bottom-cart-info span {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

.bottom-cart-cont:last-child {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 175%;
  text-align: center;
  color: var(--text-light-primary);
}

.bottom-cart-cont a {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  padding: 0.5em 1.1em 0.35em;
  transition: all 0.3s ease;
}

.bottom-cart-cont a + span {
  margin-top: 1.5em;
}

.bottom-cart-cont a:hover,
.bottom-cart-cont a:focus {
  background: #8c5bff;
}

.checkout-btn {
  background: var(--text-light-primary);
  border: 1px solid rgba(255, 255, 255, 0.01);
  border-radius: 72px !important;
}

.top-cart-items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0px 30px;
  overflow-x: hidden;
  overflow: auto;
  height: 38vh;
}

.cart-item-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: rgba(112, 112, 124, 0.2);
  border-radius: 11px;
  padding: 10px;
  position: relative;
}

.single-item-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
}

.single-item-info h4 {
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 140%;
  color: var(--text-light-primary);
  text-transform: none;
  letter-spacing: 0;
  margin-bottom: 0.2em;
}

.single-item-price img {
  height: 12px;
}

.single-item-price {
  display: flex;
  flex-direction: row;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  color: var(--text-light-primary);
  font-family: "PP Telegraf";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  font-feature-settings: "pnum" on, "lnum" on;
  flex-wrap: wrap;
}

.single-item-price div:last-child {
  width: 100%;
  text-align: left;
}

.cart-item-cont > :first-child {
  height: 84px;
}

@media screen and (max-width: 1770px) {
  .cart-modal {
    width: 35vw;
  }
}

@media screen and (max-width: 956px) {
  .cart-modal {
    width: 50vw;
  }
}

@media screen and (max-width: 680px) {
  .cart-modal {
    width: 70vw;
  }
}

@media screen and (max-width: 490px) {
  .cart-modal {
    width: 85%;
  }
  .top-cart-heading h2 {
    font-size: 32px;
  }
  .single-item-info h4 {
    font-size: 14px;
  }
}

@media screen and (max-width: 490px) {
  .cart-modal {
    height: 80%;
  }
}
