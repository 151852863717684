.checkout-success-layout {
  display: flex;
  flex-direction: column;
  max-width: 1700px;
  margin: 5% auto 10% auto;
  padding: 0 2%;
  gap: 12px;
}

.checkout-success-layout > * {
  font-family: "PP Telegraf";
  font-style: normal;
  color: var(--text-light-primary);
}

.success-title {
  font-weight: 700;
  font-size: 49px;
  line-height: 100%;
}

.success-desc {
  font-weight: 400;
  font-size: 18px;
  line-height: 175%;
  width: 479px;
}

.success-order-summary {
  background: rgba(199, 196, 231, 0.08);
  backdrop-filter: blur(50px);
  border-radius: 15px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.success-order-summary-title {
  font-weight: 700;
  font-size: 23px;
  line-height: 140%;
}

.success-order-summary-desc {
  font-weight: 400;
  font-size: 13px;
  line-height: 175%;
}

.success-order-summary-subtitle {
  width: 239px;
  font-weight: 700;
  font-size: 17px;
  line-height: 140%;
}

.success-order-summary-row {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.success-order-info {
  padding: 8px;
  width: 100%;
  background: rgba(199, 196, 231, 0.08);
  backdrop-filter: blur(50px);
  border-radius: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.success-order-items-grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.single-item-price span {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  text-align: center;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: var(--text-light-primary);
}

@media screen and (max-width: 660px) {
  .success-order-items-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .success-desc {
    width: 335px;
  }
}

@media screen and (max-width: 460px) {
  .success-order-summary-row {
    flex-direction: column;
  }
}
